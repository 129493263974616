/*
* Component is over-written to customize for Briefbase app
* @author Sreenivas Mannur
*/
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AxisOktaAuthService } from '../auth/axis-okta-auth.service';
import { ConfigConstants } from '../../model/config-constants';
import { AuthService } from '../../services/auth.service';
import { GmdService } from 'src/app/services/gmd.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, 
    private axisOktaAuthService: AxisOktaAuthService,  private route: Router, private gmdService: GmdService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') >= 0 || (req.url.indexOf(ConfigConstants.MULE_EXT_URL) >= 0 && req.url.indexOf('marketing-guidelines-docs/1') >= 0)) {
      return next.handle(req);
    }

    let authHeaders : any = new HttpHeaders().set('Content-Type', 'application/json');
    if (ConfigConstants.APP_ADMIN && this.authService.isActiveAdminUser()) { //Internal Auth Headers - No Client Id
      authHeaders = req.clone({
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.axisOktaAuthService.accessToken.value}`,
          'O-Client-ID': this.axisOktaAuthService.clientId,
          'Content-Type': 'application/json'
        })
      });
    } else { //External Auth Headers - Only Client_id is required
      authHeaders = req.clone({
        headers: new HttpHeaders({
          'CLIENT_ID': ConfigConstants.CLIENT_ID
        })
      });
    }

    //return next.handle(authHeaders);
    return next.handle(authHeaders).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Modify response if needed before passing it to browser
          //console.log('event--->>>', event);
          //console.log('request header--->>>', req);'
          const msg = event.body.message || event.body.Message;
          const errorMsg = event.body.errorMessage;
          if (msg) {
            this.gmdService.notificationMessage.next(msg.toString());
          }
          if (errorMsg) {
            this.gmdService.errorNotificationMessage.next(errorMsg.toString());
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorResponse = {'errorCode':100,'errorMessage':''};
        if (error.error instanceof ErrorEvent) {
          // Client side error
          errorResponse.errorMessage = `Error: + ${error.error.message}`;
        } else {
          // Server side error
          errorResponse.errorCode = error.status;
          errorResponse.errorMessage = error.statusText; //`Error Code: ${error.status} \n Message: ${error.message}`;
          switch (error.status) {
            case 400:     // API Validation Failure
              errorResponse.errorMessage = (error.error && error.error.errorDescription)?error.error.errorDescription:error.statusText;
              console.log('400 error :: '+errorResponse.errorMessage);
              break;
            case 401:      // Unathorized Status - Token Expiry
              console.log('401 error :: ' + errorResponse.errorMessage);
              this.axisOktaAuthService.login(this.route.url);
              break;
            case 403:     // forbidden
              console.log('403 error :: ' + errorResponse.errorMessage);
              this.axisOktaAuthService.login(this.route.url);
              break;
            case 404:     // Not Found
              console.log('404  error :: '+errorResponse.errorMessage);
              break;
            case 500:     // Internal Serve Error
              console.log('500 error :: '+errorResponse.errorMessage);
              break;
            case 503:     // fService Unavailable
              console.log('503 error :: '+errorResponse.errorMessage);
              break;
          }
        }
        return throwError(errorResponse);
      })
    );
  }
}
