import { Component, OnInit, ViewChild } from '@angular/core';
import { DirectoriesServiceService } from '../services/directories.service';
import { LoadingService } from 'axis-components/components/loading/loading.service';
@Component({
  selector: 'app-modal-firm',
  templateUrl: './modal-firm.component.html',
  styleUrls: ['./modal-firm.component.scss'],
  providers: [DirectoriesServiceService, LoadingService]
})

export class ModalFirmComponent implements OnInit {
  @ViewChild('modalFirm') modalFirm;
  constructor(private _getdirectories: DirectoriesServiceService,
              public loadingService: LoadingService) { }
  firmDesc = [];
    counselName: any;
  ngOnInit() {
  }
  openModal(panelId, counselId, counselName): void {
    this.counselName = counselName;
    this.modalFirm.open();
    this.getFirmDesc(panelId, counselId);
  }
   /* Loading Firm Description */
   getFirmDesc(modalId, firmId): void {
    this.loadingService.start();
    this._getdirectories.getFirmsDesc(modalId, firmId)
      .subscribe((resPanelData) => {
        this.firmDesc = resPanelData.resultSet1;
        this.loadingService.stop();
      },err=>{
        this.loadingService.stop();
      });
  }
  printdiv(printpage) {
  const divToPrint = document.getElementById(printpage);
  const newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    newWin.document.close();
    setTimeout(function(){newWin.close(); } , 10);
  }
}
