import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders  } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ConfigConstants  } from '../model/config-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GmdService {
  appRole;
  entitlement;
  public headerCompanyName = new BehaviorSubject<any>("--");
  public headerCompanyType = new BehaviorSubject<any>("--");
  public notificationMessage = new BehaviorSubject<any>("");
  public errorNotificationMessage = new BehaviorSubject<any>("");
  public locationCount = new BehaviorSubject<any>(0);
  public contactCount = new BehaviorSubject<any>(0);
  constructor(private _http: Http, private authService: AuthService) { 
    this.authService.appRole.subscribe((obs) => this.appRole = obs);
    this.authService.entitlement.subscribe((obs) => this.entitlement = obs);
  }
  getMarineMuleEP(){
    return (ConfigConstants.APP_ADMIN && this.authService.isActiveAdminUser())?ConfigConstants.MULE_URL:ConfigConstants.MULE_EXT_URL;   
  }

  getRegions() {
    return this._http.get(this.getMarineMuleEP() + `globalmarine/document/getRegion`);
  }
  getRegionsExternal() {
    return this._http.get(ConfigConstants.MULE_EXT_URL + `globalmarine/document/getRegion`);
  }
  getCountries(getCountryInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/getCountry`, getCountryInput);
  }
  getCountriesExternal(getCountryInput) {
    return this._http.post(ConfigConstants.MULE_EXT_URL + `globalmarine/document/getCountry`, getCountryInput);
  }
  getLocations(getLocationsInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/getLocation`,getLocationsInput); 
  }
  searchCompany(searchCompanyInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/searchCompany`, searchCompanyInput);
  }
  searchCompanyInternal(searchCompanyInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/searchCompanies`, searchCompanyInput);
  }
  getCompanyLocation(input) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/contacts/getCompanyLocation`, input);
  }
  getContacts(getContactsInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/contacts/getContacts`, getContactsInput);
  }
  getLocationsEdit(getContactLocationsInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/location/getLocations`, getContactLocationsInput);
  }
  getStates(getStatesInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/location/getStates`, getStatesInput);
  }
  getCompany(getCompanyInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/getCompany`, getCompanyInput);
  }
  saveCompany(saveCompanyInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/saveCompany`, saveCompanyInput);
  }
  saveLocations(saveLocationsInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/location/saveLocations`, saveLocationsInput);
  }
  saveContacts(saveContactsInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/contacts/saveContacts`, saveContactsInput);
  }
  downloadCSV() {
    return this._http.get(this.getMarineMuleEP() + `globalmarine/document/reportGenerationCsv`, {responseType: 'arraybuffer'});
  }
  downloadPDF() {
    return this._http.get(this.getMarineMuleEP() + `globalmarine/document/reportGenerationPdf`, {responseType: 'arraybuffer'});
  }
  getPublicPageData(getPublicPageInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/getWelcome`, getPublicPageInput);
  }
  getPublicPageExternal(getPublicPageInput) {
    return this._http.post(ConfigConstants.MULE_EXT_URL + `globalmarine/document/getWelcome`, getPublicPageInput);
  }
  savePublicPageData(savePublicPageInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/updateWelcome`, savePublicPageInput);
  }
  deleteCompany(deleteCompanyInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/document/deleteCompany`, deleteCompanyInput);
  }
  deleteLocation(deleteLocationInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/location/deleteLocations`,deleteLocationInput);
  }
  deleteContact(deleteContactInput) {
    return this._http.post(this.getMarineMuleEP() + `globalmarine/contacts/deleteContacts`,deleteContactInput);
  }
}
