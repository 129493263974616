import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AxisSharedModule } from './_shared/axis-shared.module';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalFirmComponent } from './modal-firm/modal-firm.component';
import { RequestInterceptorService } from './_services/interceptor/request.interceptor.service';
import { ConfigService } from './_services/config/config.service';
import { AxisOktaAuthService } from './_services/auth/axis-okta-auth.service';

// directives
import { StickyNewDirective } from './_directives/sticky.directive';
import { AuthService } from './services/auth.service';
import { DirectoriesServiceService } from './services/directories.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoadingService } from 'axis-components/components/loading/loading.service';
import { WelcomeService } from './services/welcome.service';
import { StateService } from './_services/state.service';
import { CountryService } from './_services/country.service';

import { UtilService } from './services/util.service';
import { SessionDetailsService } from './services/session.details.service';
import { BrowseItemDpTemplateComponent } from '../assets/upload/browselistitem.template';

import { DatePipe } from '@angular/common';
import { FooterComponent } from './GMD/footer/footer.component';

export function configLoader(config: ConfigService) {
  return () => config.load();
}
@NgModule({
  declarations: [
    AppComponent,
    ModalFirmComponent,
    routingComponents,
    StickyNewDirective,
    DashboardComponent,
    BrowseItemDpTemplateComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AxisSharedModule
  ],
  providers: [DatePipe, AuthService , WelcomeService , DirectoriesServiceService , LoadingService , StateService,
  UtilService, CountryService, SessionDetailsService,
  {
    provide: APP_INITIALIZER,
    useFactory: configLoader,
    deps: [ConfigService],
    multi: true
  }, AxisOktaAuthService,
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true }
],
  entryComponents: [BrowseItemDpTemplateComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
