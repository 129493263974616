import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAxisStickyNew]'
})
export class StickyNewDirective {

  private el: HTMLInputElement;

  private stickyClassBody = 'axis-sticky-body'; // required
  private stickyClassCustom = 'axis-sticky-custom'; // placeholder, prevent undefined
  private stickyClassDefault = 'axis-sticky';
  private stickyOffset;
  private stickyPosition = -1;
  private stickyScrollup = false;

  @Input()
  set appAxisStickyNew(stickyVal: number) { // axisSticky="n"
    this.stickyOffset = stickyVal || 0; // # pixels to scroll down until element sticks (default is here)
  }

  @Input()
  set axisStickyClassBody(stickyClassBodyAttr: string) { // axisStickyClassBody="class"
    this.stickyClassBody = stickyClassBodyAttr; // # class to add to body element
  }

  @Input()
  set axisStickyClass(stickyClassAttr: string) { // axisStickyClass="class"
    this.stickyClassCustom = stickyClassAttr; // # class to add to sticky element
  }

  @Input()
  set axisStickyScrollup(stickyScrollup: boolean) { // axisStickyScrollup="true"
    this.stickyScrollup = stickyScrollup; // # if true, show sticky element on scroll up
  }

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  // future: add debounce (reduce number of times executed, such as every 250ms)
  @HostListener('window:scroll', ['$event']) // keep tight; executes on scroll
  fixElement(event) {

    if (this.stickyScrollup) {
      if (window.pageYOffset >= this.stickyOffset) {
        this.el.classList.add(this.stickyClassDefault, this.stickyClassCustom);
        document.body.classList.add(this.stickyClassBody);
        if (window.pageYOffset < this.stickyPosition) {
          if (window.pageYOffset > 0) {
            document.body.classList.add('axis-sticky-scrolldown');
          }
          document.body.classList.add('axis-sticky-scrollup');
          this.el.style.marginTop = '0px';
        } else {
          document.body.classList.remove('axis-sticky-scrollup');
          this.el.style.marginTop = '-' + this.stickyOffset.toString() + 'px';
        }
      } else if (window.pageYOffset === 0) {
        this.el.classList.remove(this.stickyClassDefault, this.stickyClassCustom);
        document.body.classList.remove(this.stickyClassBody, 'axis-sticky-scrolldown');
        document.body.classList.add('axis-sticky-scrollup');
        this.el.style.marginTop = '0px';
      }
      this.stickyPosition = window.pageYOffset;

    } else {

      if (window.pageYOffset >= this.stickyOffset) {
        this.el.classList.add(this.stickyClassDefault, this.stickyClassCustom);
        document.body.classList.add(this.stickyClassBody);
      } else if (window.pageYOffset === 0) {
        this.el.classList.remove(this.stickyClassDefault, this.stickyClassCustom);
        document.body.classList.remove(this.stickyClassBody);
      }

    }

    // console.log('scroll event', document.body.scrollTop); // show scroll position
    // console.log(window.pageYOffset+' '+this.stickyOffset+' '+this.stickyPosition);

  }

}
