import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginCallbackComponent } from 'axis-components/components/okta/components/login-callback.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FLComponent } from './FL/FL.component';

const isChecked = window.localStorage.getItem('welcomebanner') ? window.localStorage.getItem('welcomebanner') : false;
const routePage = isChecked === 'false' ? 'dashboardSorting' : 'dashboard';
const routes: Routes = [
  { path: 'implicit/callback', component: DashboardComponent },
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboardSorting', loadChildren: './dashboard-sorting/dashboard-sorting.module#DashboardSortingModule'},
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule'},
  { path : 'gmd', loadChildren : './GMD/GMD.module#GMDModule'},
  { path : '', redirectTo : '', pathMatch : 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  DashboardComponent
];

