export const ConfigConstants = {
  'APP_INSTANCE_URL': '',
  'APP_URL':'',
  'APP_ADMIN': false,
  'CLIENT_ID': '',
  'MULE_URL': '',
  'MULE_EXT_URL': '',
  'APP_ADMIN_GROUP':'App_Briefbase-5353-Admin',
  'FL_APP_ADMIN_NON_PROD': 'App_Briefbase-5353-Admin',
  'FL_APP_ADMIN_PROD': 'App_Briefbase-5353-Admin-PROD',
  'GMD_APP_ADMIN_GROUP': 'App_Briefbase-Marine-5353-Admin',
  'ERROR_MSG':'An unexpected error occurred, Please try again or contact support ',
  'ERROR_MSG_404':'Operation Not Found, Please try again or contact support',
  'ERROR_MSG_500':'Internal Server Error, Please try again or contact support',
  'ERROR_MSG_502':'Connection Failure, Please try again or contact support',
  'FL_APP': 'FL',
  'GMD_APP': 'GMD'
};