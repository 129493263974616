import { State } from './state';

export const STATES: State[] = [
  {
    id: '1',
    dropdown: 'Alabama - AL',
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    id: '2',
    dropdown: 'Alaska - AK',
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    id: '3',
    dropdown: 'American Samoa - AS',
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    id: '4',
    dropdown: 'Arizona - AZ',
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    id: '5',
    dropdown: 'Arkansas - AR',
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    id: '6',
    dropdown: 'California - CA',
    name: 'California',
    abbreviation: 'CA'
  },
  {
    id: '7',
    dropdown: 'Colorado - CO',
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    id: '8',
    dropdown: 'Connecticut - CT',
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    id: '9',
    dropdown: 'Delaware - DE',
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    id: '10',
    dropdown: 'District of Columbia - DC',
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    id: '11',
    dropdown: 'Federated States Of Micronesia - FM',
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    id: '12',
    dropdown: 'Florida - FL',
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    id: '13',
    dropdown: 'Georgia - GA',
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    id: '14',
    dropdown: 'Guam - GU',
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    id: '15',
    dropdown: 'Hawaii - HI',
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    id: '16',
    dropdown: 'Idaho - ID',
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    id: '17',
    dropdown: 'Illinois - IL',
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    id: '18',
    dropdown: 'Indiana - IN',
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    id: '19',
    dropdown: 'Iowa - IA',
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    id: '20',
    dropdown: 'Kansas - KS',
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    id: '21',
    dropdown: 'Kentucky - KY',
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    id: '22',
    dropdown: 'Louisiana - LA',
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    id: '23',
    dropdown: 'Maine - ME',
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    id: '24',
    dropdown: 'Marshall Islands - MH',
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    id: '25',
    dropdown: 'Maryland - MD',
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    id: '26',
    dropdown: 'Massachusetts - MA',
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    id: '27',
    dropdown: 'Michigan - MI',
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    id: '28',
    dropdown: 'Minnesota - MN',
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    id: '29',
    dropdown: 'Mississippi - MS',
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    id: '30',
    dropdown: 'Missouri - MO',
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    id: '31',
    dropdown: 'Montana - MT',
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    id: '32',
    dropdown: 'Nebraska - NE',
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    id: '33',
    dropdown: 'Nevada - NV',
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    id: '34',
    dropdown: 'New Hampshire - NH',
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    id: '35',
    dropdown: 'New Jersey - NJ',
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    id: '36',
    dropdown: 'New Mexico - NM',
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    id: '37',
    dropdown: 'New York - NY',
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    id: '38',
    dropdown: 'North Carolina - NC',
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    id: '39',
    dropdown: 'North Dakota - ND',
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    id: '40',
    dropdown: 'Northern Mariana Islands - MP',
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    id: '41',
    dropdown: 'Ohio - OH',
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    id: '42',
    dropdown: 'Oklahoma - OK',
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    id: '43',
    dropdown: 'Oregon - OR',
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    id: '44',
    dropdown: 'Palau - PW',
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    id: '45',
    dropdown: 'Pennsylvania - PA',
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    id: '46',
    dropdown: 'Puerto Rico - PR',
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    id: '47',
    dropdown: 'Rhode Island - RI',
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    id: '48',
    dropdown: 'South Carolina - SC',
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    id: '49',
    dropdown: 'South Dakota - SD',
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    id: '50',
    dropdown: 'Tennessee - TN',
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    id: '51',
    dropdown: 'Texas - TX',
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    id: '52',
    dropdown: 'Utah - UT',
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    id: '53',
    dropdown: 'Vermont - VT',
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    id: '54',
    dropdown: 'Virgin Islands - VI',
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    id: '55',
    dropdown: 'Virginia - VA',
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    id: '56',
    dropdown: 'Washington - WA',
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    id: '57',
    dropdown: 'West Virginia - WV',
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    id: '58',
    dropdown: 'Wisconsin - WI',
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    id: '59',
    dropdown: 'Wyoming - WY',
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];
