import { Country } from '../../model/country';

export const COUNTRIES: Country[] = [
  {
    id: '0',
    name: 'United States'
  },
  {
    id: '1',
    name: 'Canada'
  },
  {
    id: '2',
    name: 'UK'
  },
  {
    id: '3',
    name: 'Indonesia'
  }
  ,
  {
    id: '4',
    name: 'Belgium'
  },
  {
    id: '5',
    name: 'Bermuda'
  },
  {
    id: '6',
    name: 'North Korea'
  },
  {
    id: '7',
    name: 'Netherlands'
  },
  {
    id: '8',
    name: 'Antarctica'
  },
  {
    id: '9',
    name: 'Ireland'
  },
  {
    id: '10',
    name: 'Australia'
  },
  {
    id: '11',
    name: 'Russia'
  },
  {
    id: '12',
    name: 'Mexico'
  },
  {
    id: '13',
    name: 'Argentina'
  },
  {
    id: '14',
    name: 'India'
  },
  {
    id: '15',
    name: 'Finland'
  },
  {
    id: '16',
    name: 'Singapore'
  }
];
