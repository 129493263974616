import { Injectable } from '@angular/core';
import { SessionDetailsService } from '../services/session.details.service';
import { SessionDetail } from '../model/session-detail';
import { DatePipe } from '@angular/common'

@Injectable()
export class UtilService {
  
  constructor(private _sessionDetailsService: SessionDetailsService, 
    private datepipe: DatePipe) {}

  getCookieValue(cookieString, cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(cookieString);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

  getQueryStringValue(url, param) {
    if (url === undefined || url == null || param === undefined || param == null) {
      return null;
    }

    const reg = new RegExp('[?&]' + param + '=([^&#]*)', 'i');
    const string = reg.exec(url);
    return string ? string[1] : null;
  }

  getSessionUserName(){
    let _userName = '';
    let sessionDetail = new SessionDetail(); 
    sessionDetail = this._sessionDetailsService.getSessionDetails();
    _userName = (sessionDetail && sessionDetail.firstName)?sessionDetail.firstName:'';
    return _userName;
  }

  getCurrentDate(){
    return this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }
}

