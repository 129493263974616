import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders as Headers} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ConfigConstants } from '../model/config-constants';
import { UtilService } from './util.service';
import { DirectoriesServiceService } from './directories.service';
import { Observable } from 'rxjs';

@Injectable()
export class WelcomeService {
  publicPageData: any;

  docAvailInd: any;
  constructor(private _http: Http, private authService: AuthService, private utilService: UtilService ,
    private DirectoriesService: DirectoriesServiceService) { }

  getMuleEP(){
    return (ConfigConstants.APP_ADMIN && this.authService.isActiveAdminUser())?ConfigConstants.MULE_URL:ConfigConstants.MULE_EXT_URL;   
  }

  post(url, body): Observable<any> {
    let reqHeaders= new Headers();
    reqHeaders = reqHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    reqHeaders = reqHeaders.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    reqHeaders =  reqHeaders.set('Pragma', 'no-cache');

    return this._http.post<any>(url, body, { headers: reqHeaders });
  }

  // Getting welcome page info..
  getPublicPageData() {

    const body = {"app":ConfigConstants.FL_APP};
    
    const _url = this.getMuleEP() + 'getPublicPageDetails';
    if (this.publicPageData) {
      return this.publicPageData; 
    } else {
      this.publicPageData = this.post(_url, body);
      return this.publicPageData;
    }
  }

  // Updating welcome content
  updateWelcomeContent(app, ContentTitle, ContentBody, updatedBy) {
    return this.DirectoriesService.updateWelcomeContent (app, ContentTitle, ContentBody,
      updatedBy);
  }
  deleteDocument() {
    return this._http.delete( this.getMuleEP() + `marketing-guidelines-docs/1`);
  }
  uploadDocument(uploadDocbody) {
    return this._http.put( this.getMuleEP() + `marketing-guidelines-docs/upload/1`, uploadDocbody);
  }
  prePopulateDoc() {
   let reqHeaders = new Headers();
    reqHeaders = reqHeaders.set('Client_id', ConfigConstants.CLIENT_ID);
    return this._http.get(this.getMuleEP() + `marketing-guidelines-docs/1/availability`, { headers: reqHeaders });
  }

  openDocument(){
    let reqHeaders = new Headers();
    reqHeaders = reqHeaders.set('Client_id', ConfigConstants.CLIENT_ID);
    reqHeaders = reqHeaders.set('Content-Type','text/html');
    const _url = ConfigConstants.MULE_EXT_URL+"marketing-guidelines-docs/1"
    return this._http.get(_url, { headers: reqHeaders, responseType: 'blob' });
  }
}
