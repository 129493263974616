import { Injectable } from '@angular/core';
import { ConfigConstants } from '../model/config-constants';
import { AxisOktaAuthService } from './../_services/auth/axis-okta-auth.service';
import { SessionDetailsService } from '../services/session.details.service';
import { SessionDetail } from '../model/session-detail';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  isAuthenticated: boolean;
  public appRole = new BehaviorSubject<string>('');
  public entitlement = new BehaviorSubject<string>('');
  public roleCount = new BehaviorSubject<number>(0);
  constructor(private _sessionDetailsService: SessionDetailsService,
    public oktaAuth: AxisOktaAuthService) {
  }

  isActiveAdminUser(){
    let _isAdminUser = false;
    let sessionDetail = this._sessionDetailsService.getSessionDetails();
    if(sessionDetail && sessionDetail.userType == 'ADMIN'){
      _isAdminUser = true;
    }
    return _isAdminUser;
  }
   
  async setUserAdminAccess() {
    console.log("AuthService::setUserAdminAccess");
     try{
        const user = await this.oktaAuth.getLoggedInUser();
        let count = 0;
        if(user && user.accessToken){
           let _tokenObj = this.getDecodedAccessToken(user.accessToken);
           if(_tokenObj){
             let _usrSessionObj = new SessionDetail();
             _usrSessionObj.firstName = _tokenObj.FirstName;
             _usrSessionObj.lastName = _tokenObj.LastName;
             _usrSessionObj.employeeId = _tokenObj.eid;
 
            //  let userRoles = (_tokenObj && _tokenObj.hasOwnProperty('appRoles'))?_tokenObj['appRoles']:[];
            //  if(userRoles && userRoles.indexOf(ConfigConstants.APP_ADMIN_GROUP) > -1){
            //    _usrSessionObj.userType = 'ADMIN';
            //    this.entitlement.next('ADMIN');
            //  }
             if (_tokenObj.app_roles.indexOf(ConfigConstants.FL_APP_ADMIN_NON_PROD) > -1 || _tokenObj.app_roles.indexOf(ConfigConstants.FL_APP_ADMIN_PROD) > -1) {
                _usrSessionObj.userType = 'ADMIN';
                this.appRole.next(ConfigConstants.FL_APP);
                sessionStorage.setItem('app', ConfigConstants.FL_APP.toString());
                count++;
                this.roleCount.next(count);
             }
             if (_tokenObj.app_roles.indexOf(ConfigConstants.GMD_APP_ADMIN_GROUP) > -1) {
                _usrSessionObj.userType = 'ADMIN';
                this.appRole.next(ConfigConstants.GMD_APP);
                sessionStorage.setItem('app', ConfigConstants.GMD_APP.toString());
                count++;
                this.roleCount.next(count);
             }
            if (_tokenObj.app_roles.indexOf(ConfigConstants.FL_APP_ADMIN_NON_PROD) < 0 && _tokenObj.app_roles.indexOf(ConfigConstants.FL_APP_ADMIN_PROD) < 0 && _tokenObj.app_roles.indexOf(ConfigConstants.GMD_APP_ADMIN_GROUP) < 0) {
              this.roleCount.next(0);
            }
             this._sessionDetailsService.setSessionDetails(_usrSessionObj);
           }
      }
     }catch (error) {
       console.log("Error in AuthService::authenticateUser :: "+error);
     }
   }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }catch (error) {
      console.log("Error in AuthService::getDecodedAccessToken :: "+error);
    }
  }
  
}
