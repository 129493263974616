import {
  Component,
  OnInit
} from '@angular/core';
@Component({
  selector: 'action-dynamic-template',
  template: `
  <div class="axis-media">
    <div class="axis-media-body">
      <div><a (click)="componentRef.itemClick($event, data)" href>{{data.file.name}}</a></div>
    </div>
    <div class="axis-media-right">
      <button axis-button data-kind="icon" (click)="componentRef.onDelete(data)">
        <axis-icon icon="delete"></axis-icon>
      </button>
    </div>
  </div>
  `
})
export class BrowseItemDpTemplateComponent {

}