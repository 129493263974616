import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpHeaders as Headers } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigConstants } from '../model/config-constants';
import { UtilService } from './util.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class DirectoriesServiceService {

  constructor(private _http: Http, 
    private utilService: UtilService, private authService: AuthService) { }

  private SelectedPanel = 'ALL';
  public showAddEditContainer = false;
  private GET_SUFFIXES:string = 'getSuffixListDetails';
  
  getMuleEP(){
    return (ConfigConstants.APP_ADMIN && this.authService.isActiveAdminUser())?ConfigConstants.MULE_URL:ConfigConstants.MULE_EXT_URL;   
  }

  post(url, body): Observable<any> {
    let reqHeaders= new Headers();
    reqHeaders = reqHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    reqHeaders = reqHeaders.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    reqHeaders =  reqHeaders.set('Pragma', 'no-cache');

    return this._http.post<any>(url, body, { headers: reqHeaders });
  }

  get(url):Observable<any> {
    let reqHeaders = new Headers();

    reqHeaders = reqHeaders.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    reqHeaders = reqHeaders.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    reqHeaders =  reqHeaders.set('Pragma', 'no-cache');

    return this._http.get(url, {
      headers: reqHeaders
    });
  }
  // Getting Panels dropdown list
  getActivePanels() {
    const url = this.getMuleEP() + 'getPanelListDetails?mode=ACTIVE';
    return this.get(url);
  }
  // Getting states dropdown list

  getStates() {
    return this.get(this.getMuleEP() + 'getListofStates');
  }

  getSuffixes() {
    return this.get(this.getMuleEP() + this.GET_SUFFIXES);
  }

  // Creating Panel
  SavePanelDetails(PanelId, PanelName, DisplaySq, Status, PanelDescription, cntryCd) {
    const url = this.getMuleEP() + 'create-editPanelListDetails';
    const body = {
      'panel_id': PanelId,
      'panel_nm': PanelName,
      'display_sqn': DisplaySq,
      'status_cd': Status,
      'panel_desc': PanelDescription,
      'country_name': cntryCd,
      };
    return this.post(url, body);
    // USP_INS_TPANEL_COUNTRYNAME
  }
  // Creating Firm
  SaveFirmDetails(FirmName, WebsiteURL, StatusID, Comments) {
    const url = this.getMuleEP() + 'createNewFirm';
    const body = {
      'create_userID': this.utilService.getSessionUserName(),
      'Today_Dt': this.utilService.getCurrentDate(),
      'Counsel_NM': FirmName,
      'URL_Address': WebsiteURL,
      'Status_CD': StatusID,
      'Comment_TX': Comments
    };
    return this.post(url, body);
    // USP_INS_TCOUNSEL
  }

  // creating location
  saveLocationDetails(LOCATION_ID, PANEL_ID, COUNSEL_ID, ADDRESS_LINE_1_TX, ADDRESS_LINE_2_TX, CITY_NM,
    STATE_CD, COUNTRY_CD, ZIP_1_TO_5_CD, ZIP_6_TO_9_CD, MOBILE_NO, STATUS_CD, CLASS_ACTION_FL, COMMENT) {
    const url = this.getMuleEP() + 'editLocation';
    if(ZIP_6_TO_9_CD !== '' && ZIP_6_TO_9_CD !== null){
      ZIP_6_TO_9_CD = ZIP_6_TO_9_CD.toString()
    }
    else {
      ZIP_6_TO_9_CD = '';
    }
      
    const body = {
      'location_id': LOCATION_ID,
      'create_userid': this.utilService.getSessionUserName(),
      'panel_id': PANEL_ID,
      'counsel_id': COUNSEL_ID,
      'address_line1': ADDRESS_LINE_1_TX,
      'address_line2': ADDRESS_LINE_2_TX,
      'city_nm': CITY_NM,
      'state_cd': STATE_CD,
      'country_cd': COUNTRY_CD,
      'zip_1_to_5': ZIP_1_TO_5_CD,
      'zip_6_to_9': ZIP_6_TO_9_CD,
      'phone_no': MOBILE_NO,
      'status_cd': STATUS_CD,
      'class_action': CLASS_ACTION_FL,
      'comments': COMMENT
    };
    return this.post(url, body);
  }

  // creating Profile
  saveProfileDetails(profile) {
    const url = this.getMuleEP() + 'create-editProfile';
    const body = {
      'data': profile.DATA,
      'panel_id': profile.PANEL_ID,
      'firm_id': profile.FIRM_ID
      };
    return this.post(url, body);
  }

  // Getting ALL Firms data with out Date
  getAllFirms(panleId) {
    const url = this.getMuleEP() + 'getFirmDetailsforPanel?panId=' + panleId;
    return this.get(url);
  }

  // Getting ALL Section Headers data based on Firm
  getAllSectionheaders(firmID) {
    const url = this.getMuleEP() + 'getSectionHeaderDropdownList';
    const body = {
      firmID: firmID
    };
    return this.post(url, body);
  }

  // Getting single State Firms data with out Date
  getStateFirms(panleId, stateId) {
    const url = this.getMuleEP() + 'getStateFirmDetails?panelId=' + panleId + '&state=' + stateId;
    return this.get(url);
  }

  // Getting ALL Firms data with out panel ID
  getAllFirmsWithOutPanel(pageNumber): Observable<any> {
   const url = this.getMuleEP() + 'getAllFirmDetails';
    return this.get(url);
  }

  // Getting Firms Description
  getFirmsDesc(panelId, firmId) {
    const url = this.getMuleEP() + 'getProfileMgmtForPanelAndFirm?panelId=' + panelId + '&firmId=' + firmId;
    return this.get(url);
  }

  // Getting ALL Firms data with  Date
  getAllFirmsWithDate(panelId, givenDate) {
    const url = this.getMuleEP() + 'getAllFirmDetailsBasedOnDate?panelId=' + panelId + '&date=' + givenDate;
    return this.get(url);
  }

  // Getting ALL Firms data with  Date
  getAllPanelsByCounselId(counselID) {
    const url = this.getMuleEP() + 'getListofPanelsByFirmID';
    const body = {
      counselID: counselID,
    };
    return this.post(url, body);
  }

  // Getting ALL Firms data with  Date
  getAllPanels(mode: string = this.SelectedPanel) {
    const url = this.getMuleEP() + 'getPanelListDetails?mode=ALL';
    return this.get(url);
  }

  // get firm location based on panel id and firm id
  getFirmLocations(firmID: number): Observable<any> {
    const url = this.getMuleEP() + 'getLocationAndContactDetails';
    const body = {
      firmID: firmID
    };
    return this.post(url, body);
  }

  // Getting Single State Firms data with  Date
  getStateFirmsWithDate(panelId, stateId, givenDate) {
    const url = this.getMuleEP() + 'getStateFirmDetailsBasedOnDate-PanelId';
    const body = {
      'state': stateId,
      'panid': panelId,
      'in_time': givenDate
      };
    return this.post(url, body);
  }

  updateWelcomeContent(app, ContentTitle, ContentBody, updatedBy) {
    const url = this.getMuleEP() + 'getAndUpdateHomePageContent';
    const body = {
      'app': app,
      'ContentTitle': ContentTitle,
      'ContentBody': ContentBody,
      'LastUpdatedBy': updatedBy
    };
    return this.post(url, body);
  }

  editFirmDetails(firmID, FirmName, WebsiteURL, StatusID, Comments) {
    const url = this.getMuleEP() + 'editExistingFirm';
    const body = {
      'Update_userID': this.utilService.getSessionUserName(),
      'Today_Dt': this.utilService.getCurrentDate(),
      'Counsel_NM': FirmName,
      'URL_Address': WebsiteURL,
      'Status_CD': StatusID,
      'Comment_TX': Comments,
      'Counsel_ID': firmID
    };
    return this.post(url, body);
  }

  getprofilemaintenanceapi(frimId) {
    const url = this.getMuleEP() + 'getProfileMaintenanceByFirm';
    const body = {
      firmID: frimId
    };
    return this.post(url, body);
  }

  saveContactDetailsNewSp(STAFF_ID, LOCATION_PANELS, COUNSEL_ID, FIRST_NM, MIDDLE_INITIAL_NM,
    LAST_NM, SUFFIX_TX, TITLE_TX, EMAIL_TX, CONTACT_PHONE_NO, MOBILE_NO, CONTACT_FAX_NUM, STATUS_CD, DIS_SQ, SPEC, SUB_PAN) {
     const url = this.getMuleEP() + 'editContact';
      const body = {
      'staffid': STAFF_ID,
      'panel_location': LOCATION_PANELS,
      'counsel_id': COUNSEL_ID,
      'firstname': FIRST_NM ,
      'middlename': MIDDLE_INITIAL_NM,
      'lastname': LAST_NM,
      'suffix': SUFFIX_TX,
      'title': TITLE_TX,
      'email': EMAIL_TX,
      'phone': CONTACT_PHONE_NO,
      'mobileno': MOBILE_NO,
      'fax': CONTACT_FAX_NUM,
      'status': STATUS_CD,
      'displaysequence': DIS_SQ,
      'speciality': SPEC,
      'subpanlavailable': SUB_PAN
      };
    return this.post(url, body);
  }

  getPanelsByFirmId(frimId, mode) {
    const url = this.getMuleEP() + 'getPanelByFirm';
    const body = {
      'mode' : mode,
      'firmID': frimId
      };
    return this.post(url, body);
  }

  deleteProfile(panelId, counsilId){
    const url = this.getMuleEP() + 'deleteProfile';
    const body = {
      'counsel_id' : counsilId,
      'panel_id': panelId
      };
    return this.post(url, body);
  }

  deleteSectionHeader(sectionHeader){
    const url = this.getMuleEP() + 'deleteSectionHeader';
    const body = {
      'counsel_id' : sectionHeader.counsel_id,
      'panel_id': sectionHeader.panel_id,
      'section_id': sectionHeader.category_id,
      'display_seq': sectionHeader.display_sqn
      };
    return this.post(url, body);
  }

  deleteContact(item){
    const url = this.getMuleEP() + 'deleteContact';
    const body = {
      'staff_id' : item.STAFF_ID
      };
    return this.post(url, body);
  }

}
