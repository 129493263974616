import { Injectable } from '@angular/core';

import { State } from './data/state';
import { STATES } from './data/states';

@Injectable()
export class StateService {
  getStates(): Promise<State[]> {
    return Promise.resolve(STATES);
  }
}
