import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ConfigConstants } from './model/config-constants';
import { config } from './_services/config/constants';
import { DirectoriesServiceService } from './services/directories.service';
import { GmdService } from './services/gmd.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  accountSelected;
  appinit = false;
  public appRole;
  public entitlement;
  public companyName;
  public companyType;
  public roleCount = 0;
  title = 'app';
  isConfigLoaded: boolean = false;
  constructor(
    private router: Router, private authService: AuthService,
    private _getdirectories: DirectoriesServiceService,
    private gmdService: GmdService) { 
    this.isConfigLoaded = config && Object.keys(config).length > 0;
    this.authService.appRole.subscribe((obs) => this.appRole = obs);
    this.gmdService.headerCompanyName.subscribe((obs)=> this.companyName = obs);
    this.gmdService.headerCompanyType.subscribe((obs)=> this.companyType = obs);
    this.authService.roleCount.subscribe((obs)=> {
      if (obs) {
        this.roleCount = obs;
      }
    });
  }

  ngOnInit() {
    console.log("AppComponent::ngOnInit:: ");
    if (this.isConfigLoaded) {
        /* Set App Environment Variables */
        ConfigConstants.APP_ADMIN = config.APP_ADMIN ? config.APP_ADMIN : false;
        ConfigConstants.CLIENT_ID = config.CLIENT_ID;
        ConfigConstants.MULE_URL = config.MULE_URL;
        ConfigConstants.MULE_EXT_URL = config.MULE_EXT_URL;
        ConfigConstants.APP_ADMIN_GROUP = config.APP_ADMIN_GROUP;
        
        this.appinit = true;
        if (ConfigConstants.APP_ADMIN === false) {
          if (this.router.url === '/dashboard' || this.router.url === '/' && !window.location.href.includes('/gmd')) {
            this.router.navigate([`./dashboard`]);
          }
          if (this.router.url === '/gmd' || window.location.href.includes('/gmd')) {
            this.router.navigate([`./gmd`]);
          }
        }
        if (ConfigConstants.APP_ADMIN === true && this.appRole !== '') {
          this.entitlement = 'ADMIN';
        } else {
          if (this.roleCount === 0) {
            this.entitlement = 'ADMIN';
          }
        }
      }
  }
  changeParams() {
    this._getdirectories.showAddEditContainer = false;
  }
  showSubNavBar() {
    return this.router.url.indexOf('admin') > -1;
  }
  switchApp(app: String) {
    if (app === ConfigConstants.FL_APP) {
      sessionStorage.setItem('app', ConfigConstants.FL_APP);
      this.router.navigate([`./dashboard`]);
    }
    if (app === ConfigConstants.GMD_APP) {
      sessionStorage.setItem('app', ConfigConstants.GMD_APP);
      this.router.navigate([`./gmd`]);
    }
  }
}
