import { Injectable } from '@angular/core';
import { SessionDetail } from '../model/session-detail';

@Injectable()
export class SessionDetailsService {
  private _sessionDetails: SessionDetail;
  setSessionDetails(sessionDetails): void {
    this._sessionDetails = sessionDetails;
  }
  getSessionDetails(): SessionDetail {
    return this._sessionDetails;
  }
}
