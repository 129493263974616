/**
 * This shared module contains all the axis-components, axis-charts, and axis-abc components modules.
 * You can comment/uncomment the modules whichever you want in your project.
 * Make sure when you uncomment any imports, that dependency should be present/install in node_modules.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// AXIS COMPONENTS
import { AxisComponentsAllModule } from 'axis-components/components/components.module';
// import { AccordionModule } from 'axis-components/components/accordion/accordion.module';
// import { AlertModule } from 'axis-components/components/alert/alert.module';
// import { AQIModule } from 'axis-components/components/aqi/aqi.module';
// import { ButtonsModule } from 'axis-components/components/button/button.module';
// import { CardModule } from 'axis-components/components/card/card.module';
// import { CarouselModule } from 'axis-components/components/carousel/carousel.module';
// import { CheckboxModule } from 'axis-components/components/checkBox/checkbox.module';
// import { ChipListModule } from 'axis-components/components/chip-list/chip-list.module';
// import { CountModule } from 'axis-components/components/countDown/countdown.module';
// import { DropdownModule } from 'axis-components/components/dropdown/dropdown.module';
// import { EditorModule } from 'axis-components/components/editor/editor.module';
// import { DatepickerModule } from 'axis-components/components/datepicker/datepicker.module';
// import { DualListModule } from 'axis-components/components/dualList/dualList.module';
// import { FileUploadModule } from 'axis-components/components/file-upload/fileupload.module';
// import { IconModule } from 'axis-components/components/icon/icon.module';
// import { InputModule } from 'axis-components/components/input/input.module';
// import { ListModule } from 'axis-components/components/list/list.module';
// import { ModalModule } from 'axis-components/components/modal/modal.module';
// import { LoadingModule } from 'axis-components/components/loading/loading.module';
// import { NavbarModule } from 'axis-components/components/navbar/navbar.module';
// import { ProgressBarModule } from 'axis-components/components/progress-bar/progressbar.module';
// import { RadioButtonModule } from 'axis-components/components/radio-button/radio-button.module';
// import { TimepickerModule } from 'axis-components/components/timepicker/timepicker.module';
// import { Scroll2tbModule } from 'axis-components/components/scroll2tb/scroll2tb.module';
// import { SliderModule } from 'axis-components/components/slider/slider.module';
// import { TabsModule } from 'axis-components/components/tab/tab.module';
// import { TooltipModule } from 'axis-components/components/tooltip/tooltip.module';
// import { TruncateModule } from 'axis-components/components/truncate/truncate.module';
// import { IntroTourModule } from 'axis-components/components/intro-tour/intro-tour.module';
// import { UtilsModule } from 'axis-components/components/utilities/utils.module';

// AXIS CHARTS
// import { AxisChartsAllModule } from 'axis-charts/charts/charts.module';
// import { AreaPlusLineChartModule } from 'axis-charts/charts/area-plus-line-chart/area-plus-line-chart.module';
// import { BarPlusMultiLineChartModule } from 'axis-charts/charts/bar-plus-multi-line-chart/bar-plus-multi-line-chart.module';
// import { BarPlusLineChartModule } from 'axis-charts/charts/bar-plus-line-chart-grouped/bar-plus-line-chart.module';
// import { BodymapChartModule } from 'axis-charts/charts/bodymap-chart/bodymap-chart.module';
// import { BubbleChartModule } from 'axis-charts/charts/bubble-chart/bubble-chart.module';
// import { DatamapsChartModule } from 'axis-charts/charts/datamaps-chart/datamaps-chart.module';
// import { DonutChartModule } from 'axis-charts/charts/donut-chart/donut-chart.module';
// import { GaugeChartModule } from 'axis-charts/charts/gauge-chart/gauge-chart.module';
// import { HorizontalBarChartModule } from 'axis-charts/charts/horizontal-bar-chart/horizontal-bar-chart.module';
// import { HorizontalStackedProgressChartModule } from 'axis-charts/charts/horizontal-stacked-progress-chart/horizontal-stacked-progress-chart.module';
// import { WordcloudChartModule } from 'axis-charts/charts/wordcloud-chart/wordcloud-chart.module';
// import { D3ChartsModule } from 'axis-charts/charts/d3-charts/d3-charts.module';
// import { UtilsModule } from 'axis-charts/charts/utilities/utils.module';

// AXIS BUSINESS COMPONENTS
// import { AxisAbcPortalMenuModule } from 'axis-abc-portal-menu/axis-abc-portal-menu.module';
// import { AxisAbcSearchAccountModule } from 'axis-abc-search-account/axis-abc-search-account.module';
// import { AxisAddressModule } from 'axis-abc-search-address/axis-google-maps.module';
// import { AxisDatagridModule } from 'axis-datagrid/axis-datagrid.module';
// import { AxisNotificationModule } from 'axis-notification/axis-notification.module';

// MODULES
export const MODULES = [

    // >>> COMPONENTS
    AxisComponentsAllModule, // (ALL)
    // AccordionModule,
    // AlertModule,
    // AQIModule,
    // ButtonsModule,
    // CardModule,
    // CarouselModule,
    // CheckboxModule,
    // ChipListModule,
    // CountModule,
    // DropdownModule,
    // DatepickerModule,
    // DualListModule,
    // EditorModule,
    // FileUploadModule,
    // InputModule,
    // IconModule,
    // ListModule,
    // ModalModule,
    // NavbarModule,
    // ProgressBarModule,
    // RadioButtonModule,
    // TimepickerModule,
    // Scroll2tbModule,
    // SliderModule,
    // TabsModule,
    // TooltipModule,
    // TruncateModule,
    // UtilsModule,
    // LoadingModule,
    // IntroTourModule,

    // >>> CHARTS
    // AxisChartsAllModule, // (ALL)
    // AreaPlusLineChartModule,
    // BarPlusMultiLineChartModule,
    // BarPlusLineChartModule,
    // BodymapChartModule,
    // BubbleChartModule,
    // DatamapsChartModule,
    // DonutChartModule,
    // GaugeChartModule,
    // HorizontalBarChartModule,
    // HorizontalStackedProgressChartModule,
    // WordcloudChartModule,
    // D3ChartsModule,
    // UtilsModule,

    // >>> Axis ABC's Components(Business components)
    // AxisAbcPortalMenuModule,
    // AxisAbcSearchAccountModule,
    // AxisAddressModule,
    // AxisDatagridModule,
    // AxisNotificationModule
];

// SHARED MODULE
@NgModule({
    imports: [CommonModule, ...MODULES],
    declarations: [],
    exports: [CommonModule, FormsModule, ...MODULES]
})
export class AxisSharedModule { }
