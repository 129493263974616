import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Country } from '../model/country';
import { COUNTRIES } from './data/countries';

@Injectable()
export class CountryService {
  getCountries(): Observable<Country[]> {
    return of(COUNTRIES);
  }
}
