import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WelcomeService } from '../services/welcome.service';
import { AuthService } from '../services/auth.service';
import { LoadingService } from 'axis-components/components/loading/loading.service';
import { SessionDetail } from '../model/session-detail';
import { WelcomePageDetails } from '../model/welcome-page-details';
import { ConfigConstants } from '../model/config-constants';
import { SessionDetailsService } from '../services/session.details.service';
import { Observable } from 'rxjs';

import { BrowseItemDpTemplateComponent } from '../../assets/upload/browselistitem.template';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [WelcomeService, AuthService]
})

export class DashboardComponent implements OnInit {
  errorEnable: boolean;
  errorMessage: string;
  // For File Upload
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('listBase') listBase: any;
  public inputComponent = BrowseItemDpTemplateComponent;
  private multidemolist: Array<any> = new Array<any>();
  docAvailInd: any;
  reqdata: any;
  saveclicked = false;
  disableSave = false;
  enableQRG: boolean = false;
  uploadDocs = [];
  mailTo:any;

  public skin_url1: any = '/assets/skins/ui/oxide';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private getWelcomeInfo: WelcomeService,
    public loadingService: LoadingService,
    private _authService: AuthService,
    private _sessionDetailsService: SessionDetailsService
  ) { }
  sessionDetails: SessionDetail;
  welcomePageDetails: WelcomePageDetails;
  featureEdit = false;
  dontShowMeFlag: boolean;
  showEditIcon = false;
  isWelcomeBannerb=window.localStorage.getItem('welcomebanner');
  appRole;
  roleCount = 0;
  ngOnInit() {
    this._authService.roleCount.subscribe((obs)=> {
      if (obs) {
        this.roleCount = obs;
      }
    });
    this._authService.appRole.subscribe((obs) => 
    {
        this.appRole = obs;
        if (ConfigConstants.APP_ADMIN === true) {
          if (sessionStorage.getItem('app')) {
            if (this.isWelcomeBannerb && sessionStorage.getItem('app') === ConfigConstants.FL_APP){
              this.router.navigate([`./dashboardSorting`]);
              this.showEditIcon = true;
              this.enableQRG = true;
            }
            if (!this.isWelcomeBannerb && sessionStorage.getItem('app') === ConfigConstants.FL_APP) {
              this.router.navigate([`./dashboard`]);
              this.showEditIcon = true;
              this.enableQRG = true;
            }
            if (sessionStorage.getItem('app') === ConfigConstants.GMD_APP) {
              this.router.navigate([`./gmd`]);
            }
          }
        } else {
          if (this.isWelcomeBannerb === "true") {
            this.router.navigate([`./dashboardSorting`]);
          } else {
            this.router.navigate([`./dashboard`]);
          }
          if (location.href.includes('/gmd')) {
            this.router.navigate([`./gmd`]);
          }
        }
    });
    
    // if (ConfigConstants.APP_ADMIN === false) {
    //   if (this.router.url === '/fl') {
    //     this.router.navigate([`./fl`]);
    //   }
    //   if (this.router.url === '/gmd') {
    //     this.router.navigate([`./gmd`]);
    //   }
    // }
    this.prePopulateDocs();
    this.getWelcomeContent();
    if(this._authService.isActiveAdminUser()){  
      this.showEditIcon = true;
      this.enableQRG = true;
    }
  }

  getWelcomeContent(): void {
    this.loadingService.start();
    if (this.welcomePageDetails) {
      this.welcomePageDetails = null;
    }
    this.getWelcomeInfo.getPublicPageData()
      .subscribe((resWelcomeContent) => {
        this.welcomePageDetails = resWelcomeContent.resultSet1;
        if (this.welcomePageDetails) {
          this.welcomePageDetails[0].ContentTitle = decodeURI(this.welcomePageDetails[0].ContentTitle);
          this.welcomePageDetails[0].ContentBody = decodeURI(this.welcomePageDetails[0].ContentBody);
          if(this.welcomePageDetails[0]['ContactUs']){
            ConfigConstants.ERROR_MSG = ConfigConstants.ERROR_MSG + this.welcomePageDetails[0]['ContactUs'];
            this.mailTo = "mailto:"+this.welcomePageDetails[0]['ContactUs'];
          }
          
       }
        setTimeout(() => {
          this.loadingService.stop();
        }, 600);
        this.featureEdit = false;
      }, 
      error => {
        this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
        window.scrollTo(0, 0);
      });
  }
  
  dontShowMeFunc(): void {
    this.dontShowMeFlag = !this.dontShowMeFlag;
    if (this.dontShowMeFlag) {
     window.localStorage.setItem('welcomebanner', 'false');
    } else {
      window.localStorage.setItem('welcomebanner', 'true');
    }
  }

  updateWelcomePage(ContentTitle, ContentBody): void {
    this.saveclicked = true;
    let userSession = this._sessionDetailsService.getSessionDetails();
    this.getWelcomeInfo.updateWelcomeContent(ConfigConstants.FL_APP, encodeURI(ContentTitle), encodeURI(ContentBody),
    userSession.firstName + ' ' + userSession.lastName)
      .subscribe((res) => { 
        this.getWelcomeContent() 
      },
      error => {
        this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
      });
  }
// File Upload Methods
  multiupdateList(list: any) {
    this.multidemolist = Array.apply(this, list);
    if (list.length) {
      this.disableSave = true;
      const reqParam = {
        documentName: this.multidemolist[0].file.name,
      };

      const reader = new FileReader();
      reader.readAsDataURL(this.multidemolist[0].file);
      this.convertBTOA(reader).subscribe(fileBase64 => {
        reqParam['documentData'] = fileBase64;
        this.uploadDoc(reqParam, this.multidemolist);
      },
      error=>{
        this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
      });
    }

  }
  uploadDoc(reqdata, list) {
    this.reqdata = reqdata;
    this.disableSave = false;
    if (this.saveclicked) {
      this.getWelcomeInfo.uploadDocument(this.reqdata).subscribe((data: any) => {
        //data = JSON.parse(data._body);
        if (data.status && data.status === 'success') {
          this.multidemolist = Array.apply(this, list);
          this.saveclicked = false;
          this.prePopulateDocs();
        } else {
          this.saveclicked = false;
        }
      },error => { 
          this.saveclicked = false; 
      });
    }
  }
  convertBTOA(reader) {
  return Observable.create((observer: any) => {
    reader.onload =
      function () {
        const base64String = (reader.result as string).split(';base64,')[1];
        observer.next(base64String); observer.complete();
      };
    });
  }
  saveData() {
    this.saveclicked = true;
    // tslint:disable-next-line:curly
    if (this.multidemolist.length)
      this.uploadDoc(this.reqdata, this.multidemolist);
    this.updateWelcomePage(this.welcomePageDetails[0].ContentTitle, this.welcomePageDetails[0].ContentBody);
    this.prePopulateDocs();
  }

  delete(data) {
    this.fileUpload.delete(data);
  }

  multiFileuploadDelete(event) {
    this.getWelcomeInfo.deleteDocument().subscribe((data: any) => {
      if (data.status === 'success') {
        this.fileUpload.delete(event);
        this.prePopulateDocs();
      }
    },
    error=>{
      this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
    }
    );
  }
  prePopulateDocs() {
    this.getWelcomeInfo.prePopulateDoc().subscribe((data: any) => {
      this.uploadDocs = [data];
    },
    error=>{
      this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
    });
  }

  deleteDoc() {
    this.getWelcomeInfo.deleteDocument().subscribe((data: any) => {
      if (data.status === 'success') {
        this.uploadDocs = [];
      }
    },
    error=>{
      this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
    }
    );
  }

  download(obj) {
    let reader = new FileReader();
    reader.onload = function (e) {
      let link = document.createElement("a");
      link.download = obj.file.name;
      link.href = reader.result.toString();
      link.target = '_blank';
      link.click();
    };
    reader.readAsDataURL(obj.file);
  }

  openDocLink(){
    //https://dev.hip7api.aig.com/extgtw1/hip7-clm-briefbase-c2a-ext-proxy/marketing-guidelines-docs/1
    // const _url = ConfigConstants.MULE_EXT_URL+"marketing-guidelines-docs/1";
    // window.open(_url, "_blank");
    const type = this.uploadDocs[0]?this.uploadDocs[0]['docName'].split('.')[1]:this.uploadDocs[0]
      this.getWelcomeInfo.openDocument().subscribe((data) => {

      const file = new Blob([data], {
        type: type,
      });
      const fileURL = URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = this.uploadDocs[0]['docName'];
      anchor.href = fileURL;
      anchor.click();
    },
      error => {
        this.errorMessage = '';
        this.errorEnable = true;
        this.loadingService.stop();
        this.errorMessage = ConfigConstants.ERROR_MSG;
      });
  }

}
