import { Component, OnInit } from '@angular/core';
import { GmdService } from 'src/app/services/gmd.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private gmdService: GmdService) { }

  ngOnInit(): void {
  }
  public downloadCSV() {
    this.gmdService.downloadCSV().subscribe((obs)=> {
      const blob = new Blob([obs], { type: 'text/csv;charset=utf-8' });
      const data = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `Marine Claims Worldwide Directory.csv`;
      a.href = data;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(data);
      a.remove();
    });
  }
  public downloadPDF() {
    this.gmdService.downloadPDF().subscribe((obs)=> {
      const blob = new Blob([obs], { type: 'text/pd;charset=utf-8' });
      const data = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `Marine Claims Worldwide Directory.pdf`;
      a.href = data;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(data);
      a.remove();
    });
  }
}
