/*
* AxisOktaAuthService is over-written to customize for Briefbase app
* @author Sreenivas Mannur
*/
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as OktaAuth from 'axis-components/components/utilities/lib-okta/okta-auth-js.min';
import { HttpClient } from '@angular/common/http';
import { config } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class AxisOktaAuthService {

  TAG = 'AxisOktaAuthService::';
  private loggedInUser: any;
  private _accessToken: any;
  private observer: Observer<boolean>;
  $isAuthenticated: Observable<boolean>;
  oktaAuth;
  constructor(private router: Router, private http: HttpClient) { }

  // invoked after config load
  initOktaAuth() {
    this.oktaAuth = new OktaAuth({
      clientId: config['okta'].clientId,
      issuer: config['okta'].issuer,
      redirectUri: config['okta'].loginRedirectUri,
      pkce: true
    });

    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(val => {
        observer.next(val);
      }).catch(err => {
        console.log(this.TAG, 'Error in okta initOktaAuth', err);
        localStorage.clear();
        window.location.reload();
      });
    });
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this.oktaAuth.tokenManager.get('accessToken') && await this.oktaAuth.tokenManager.get('idToken'));
  }

  login(originalUrl: string) {
    // Save current URL before redirect
    sessionStorage.setItem('okta-app-url', originalUrl || this.router.url);
    let arrScopes = ['openid', 'email', 'profile', 'gi-apps'];
    if (config.scopes && config.scopes.length > 0) {
      arrScopes = config.scopes;
    }
    // Launches the login redirect.
    // https://developer.okta.com/docs/reference/api/oidc/#access-token-scopes-and-claims
    this.oktaAuth.token.getWithRedirect({
      scopes: arrScopes
    });
  }

  async handleAuthentication() {
    try {
      const res = await this.oktaAuth.token.parseFromUrl();
      this.oktaAuth.tokenManager.add('idToken', res.tokens.idToken);
      this.oktaAuth.tokenManager.add('accessToken', res.tokens.accessToken);
      if (await this.isAuthenticated()) {
        this.getLoggedInUser();
        if (this.observer) {
          this.observer.next(true);
        } else {
          const abc = new Observable((observer: Observer<boolean>) => {
            this.observer = observer;
            observer.next(true);
          });
          console.log(this.TAG, 'handleAuthentication else block ');
        }
      }
      // Retrieve the saved URL and navigate back
      const url = sessionStorage.getItem('okta-app-url');
      this.router.navigate([url]);
    } catch (err) {
      console.log(this.TAG, 'Error in handleAuthentication ', err);
    }
  }

  async logout() {
    localStorage.clear();
    await this.oktaAuth.revokeAccessToken(this.accessToken);
  }

  get accessToken() {
    return this._accessToken;
  }

  get clientId() {
    return config['okta'] && config['okta'].clientId ? config['okta'].clientId : '';
  }

  async getLoggedInUser() {
    const accessToken = await this.oktaAuth.tokenManager.get('accessToken');
    if (!accessToken) {
      this.loggedInUser = undefined;
      this._accessToken = undefined;
      return;
    }
    this._accessToken = accessToken;

    const idToken = await this.oktaAuth.tokenManager.get('idToken');
    if (!idToken || !idToken.claims) {
      this.loggedInUser = undefined;
    }

    this.loggedInUser = {
      email: idToken.claims.email,
      name: idToken.claims.name,
      preferred_username: idToken.claims.preferred_username,
      accessToken: accessToken.value
    };
    return this.loggedInUser;
  }
}
