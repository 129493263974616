/*
* AXIS ConfigService is over-written to customize for Briefbase app
* @author Sreenivas Mannur
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from './constants';
import { AxisOktaAuthService } from '../auth/axis-okta-auth.service';
import { AuthorizationService } from 'axis-components/components/okta/services/authorization.service';
import { AuthService } from './../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  TAG = 'ConfigService::';

  constructor(private http: HttpClient, private axisOktaAuthService: AxisOktaAuthService,
    private authService: AuthorizationService,
    private appAuthService: AuthService) {
  }

  public load() {
    console.log(this.TAG, 'Start of load Method');
    return new Promise((resolve, reject) => {
      this.loadConfig()
        .then(res => {
          console.log(this.TAG, 'loadConfig :: resolved');
          if (config && config.APP_ADMIN) {
            return this.isOktaAuthenticated();
          } else {
            console.log(this.TAG, 'isOktaAuthenticated :: skipped');
            return resolve(false);
          }
        })
        .then(res => {
          if(res){
            console.log(this.TAG, 'isOktaAuthenticated :: resolved');
            return this.getLoggedInUser();
          }else{
            console.log(this.TAG, 'getLoggedInUser :: skipped');
            return resolve(false);
          }
        })
        .then(res => {
          console.log(this.TAG, 'getLoggedInUser :: resolved');
          if (res) {
            this.appAuthService.setUserAdminAccess();
          }
          return resolve(true);
        })
        .then(res => {
          console.log(this.TAG, 'setUserAdminAccess :: resolved');
          /*if (res) {
            console.log(this.TAG, 'getPermissions resolved');
            this.doRoleBasedRedirection();
          }*/
          return resolve(true);
        })
        .catch(err => {
          console.log('Error in one of the chains', err);
        });
    });
  }

  loadConfig() {
    return new Promise((resolve, reject) => {
      console.log(this.TAG, 'Start of loadConfig');
      this.http.get('assets/config/config.json').subscribe((keyVal: any) => {
        for (const key in keyVal) {
          if (!keyVal[key]) {
            continue;
          }
          config[key] = keyVal[key];
        }
        if(config && config.APP_ADMIN)
          config.logoutRedirectUri = `${this.getHostLocation()}${keyVal['okta']['logoutRedirectUri']}`;
        return resolve(config);
      },
        err => {
          console.log(err);
          return reject(err);
        }
      );
    });
  }

  isOktaAuthenticated() {
    console.log(this.TAG, 'Start of isOktaAuthenticated');
    return new Promise((resolve, reject) => {
      this.axisOktaAuthService.initOktaAuth();
      const path = window.location.pathname;
      if (path !== '/implicit/callback') {
        this.axisOktaAuthService.isAuthenticated().then(res => {
          if (res) {
            return resolve(true);
          } else {
            this.axisOktaAuthService.login(path);
            return reject(false);
          }
        }).catch(err => {
          console.log(this.TAG, 'Error in isOktaAuthenticated :: ');
          if(err && err.errorCode === 'login_required'){
            console.log(this.TAG, err.errorCode);
            console.log(this.TAG, err.message);
            // console.log(this.TAG, path);
            //this.axisOktaAuthService.login(path);
            //this.axisOktaAuthService.login(this.route.url);
            this.axisOktaAuthService.login("/");
          }
          return reject(false);
        });
      } else {
        this.axisOktaAuthService.handleAuthentication().then(res => {
          return resolve(true);
        });
      }
    });
  }

  getLoggedInUser() {
    console.log(this.TAG, 'Start of getOktaCreds');
    return this.axisOktaAuthService.getLoggedInUser();
  }

  getPermissions() {
    console.log(this.TAG, 'Start of getPermissions');
    if (config.baseUrl) {
      return this.authService.initializePermissions();
    } else {
      return false;
    }
  }

  getHostLocation() {
    return window.location.protocol + '//' + window.location.host;
  }

  doRoleBasedRedirection() {
    console.log(this.TAG, 'Start of doRoleBasedRedirection');
    /*
    if (!this.authService.permissions || this.authService.permissions.length === 0) {
      this.router.navigate(['/unauthorized']);
    }
    */
    const path = window.location.pathname;
    if (path === '/') {
      // apply permission based routing

      /*
      if (this.authService.permissions.length === 4) {
        this.router.navigate(['/applications']);
      } else {
        this.router.navigate(['/roleManagement']);
      }
      */
    }
  }
}
